.required-config-container {
  display: flex;
  margin: 20px;
  background: #fee2e2;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #f4404b;
  color: black;
}

.required-config-icon {
  margin-right: 10px;
}

.required-config-icon > * {
  color: #f4404b;
}

.required-config-container ul {
  font-weight: bolder;
  text-decoration: underline;
  margin: 0;
}

.required-config-container ul li {
  margin: 5px 0;
}

.required-config-container ul a {
  color: black;
}
