.icon-help-menu {
  margin-right: 15px;
  color: #3d3d3d;
}

.icon-help-header {
  color: #3d3d3d;
}

.feedback-container {
  position: absolute;
  width: 350px;
  height: 525px;
  bottom: 20px;
  right: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  border-radius: 5px;
  z-index: 2;
}

.feedback-header-container {
  background-color: var(--audience-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  position: relative;
  color: white;
  height: 57px;
  align-items: center;
  justify-content: center;
}

.close-feedback {
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.feedback-form-container {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
}

.feedback-form-label {
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-feedback {
  font-size: 18px;
}

.feedback-form-send-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
