a:hover {
  text-decoration: none !important;
}

p {
  margin: 0;
}

label {
  margin-bottom: 0;
}

.required:after {
  content: '*';
  color: var(--danger);
  margin: 0 2px;
}

.react-select-error > * {
  border-color: var(--danger) !important;
}

.disabled {
  opacity: 0.5;
}

.action-container-buttons {
  display: flex !important;
  justify-content: flex-end !important;
}

.action-container-buttons > *:first-child {
  margin-left: 0 !important;
}

.action-container-buttons > * {
  margin-left: 10px !important;
}

/*--------- DETAIL BANNER PAGE -------*/
.bannerBackground {
  background: #2e2e2e;
  padding: 20px 0 20px 45px;
  height: 250px;
  position: relative;
  overflow: hidden;
}

.bannerBackground::before {
  content: '';
  width: 250px;
  height: 400px;
  position: absolute;
  right: -80px;
  top: -30px;
  background: #d9d9d9;
  transform: rotate(30deg);
  border: 15px solid #e7e7e7;
  outline: 15px solid #f5f5f5;
  outline-offset: 0;
}

.headerContent-buttons {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 30px;
}

.headerContent-buttons > * {
  margin-bottom: 10px !important;
}

/*------------------------------------------*/

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  align-items: flex-start;
}

.MuiMenu-list > li {
  padding: 10px;
  width: 100%;
  justify-content: flex-start;
  color: #3d3d3d;
  font-weight: 300;
}

.overlay-disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  color: black;
}

.react-datepicker-wrapper {
  width: 100%;
}

.sortable-table-container {
  background-color: rgba(250, 250, 250);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sortable-table-container > td:nth-child(1) {
  width: 40px;
}

.sortable-table-container > td:nth-child(2),
.sortable-table-container > td:nth-child(3) {
  width: 50%;
}
