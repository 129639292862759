/*Avatar Styles*/
.user-avatar {
  margin-right: 8px;
  border: 2px solid white;
  @extend %size-50;

  & img {
    max-width: 100%;
    height: auto;
  }

  &-lg {
    margin-right: 10px;
    @extend %size-60;
  }
}

.user-avatar-guest {
  margin-right: 8px;
  border: 1.5px solid #b1afaf;
  @extend %size-50;

  & img {
    max-width: 100%;
    height: auto;
  }

  &-lg {
    margin-right: 10px;
    @extend %size-60;
  }
}

.user-detail {
  & .user-name {
    margin-bottom: 2px;
    font-weight: 400;
    text-transform: none;
  }

  & .user-description {
    font-size: 13px;
    margin-bottom: 0;
    color: $light-gray;
  }
}

.avatarGuest{
  margin-top: 20%;
  align-content: center;
  text-align: center;
  margin-left: 15%;
  height: auto !important;
  width: 70% !important;
}
