.nav-menu-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.nav-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-list {
  flex: 1;
}

.footer-power-by {
  margin: 20px auto;
  text-align: center;
  width: 50%;
}

.footer-power-by img {
  margin: 5px auto 0;
  width: 100%;
}
