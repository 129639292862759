/*Base Styles*/
html {
  height: 100%;
}

td.MuiTableCell-root:first-child {
  padding-left: 5px !important;
}

th.MuiTableCell-root:first-child {
  padding-left: 5px !important;
}

.headerAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 45px;
}

.btn-shop {
  color: #5a5a5a;
  font-size: 20px;
  margin-top: 5px;
  margin-right: 5px;
}

.btn-shop:hover {
  cursor: pointer;
}

.bg-card-dashboard-ticketing {
  background-color: #67b7dc !important;
  padding: 10px 0 !important;
}

body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-base;
  }
  height: 100%;
  overflow: auto;
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

div[tabindex='-1'] {
  z-index: 1200 !important;
}

.col-with-divider div[class*='col-'] {
  border-left: solid 1px $gray-300;
  &:first-child {
    border: 0 none;
  }
}

//Center Crop image

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 118px;
  overflow: hidden;

  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  & img.portrait {
    width: 100%;
    height: auto;
  }
}

//Buttons
a.btn-info {
  @extend %link-hover;
}

a.btn-danger {
  @extend %link-hover;
}

a.btn-warning {
  @extend %link-hover;
}

a.btn-success {
  @extend %link-hover;
}

a.btn-dark {
  @extend %link-hover;
}

//Light Color
.text-light {
  color: lighten($black, 60%) !important;
}
.text-light-grey {
  color: $light-gray !important;
}

a.text-light {
  &:hover,
  &:focus {
    background-color: lighten($black, 50%) !important;
  }
}

//Separator

.jr-border-radius {
  @include border-radius($border-radius);
}

.jr-list-half .jr-list-item {
  width: 50%;
}

.list-inline li {
  padding: 0 5px;
}

.quick-menu.show .dropdown-menu {
  opacity: 1;
  transform: translate3d(0, 30px, 0) !important;
  visibility: visible;
}

.header-notifications .app-notification .dropdown-menu {
  width: 210px;
}
.quick-menu .dropdown-menu {
  display: block;
  visibility: hidden;
  left: auto !important;
  right: 0 !important;
  opacity: 0;
  transform: translate3d(0, 100px, 0) !important;
  transition: all 0.2s linear 0ms;
}
.quick-menu .dropdown-menu {
  padding: 5px 10px;
  width: 350px;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}
div[tabindex='-1'] {
  z-index: 1200 !important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #575757;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
}
.dropdown-menu {
  font-size: 1rem;
  color: #212529;
  border-radius: 0.25rem;
}

.jr-list-link {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  transition: all 0.5s ease;
  border-radius: 0.375rem;
  padding: 10px;
  color: #6c757d;
  border: 1px solid transparent;
}

// Custom List
.jr-list {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__item {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 14px;
  }

  &-bordered &-item:not(:last-child) {
    border-right: $jr-border;
  }

  &-half &-item {
    width: 50%;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.subtext {
  display: block;
  font-size: 12px !important;
  margin-left: 0;
  margin-top: -7px;
  color: #6c757d !important;
}

/*Typography Styles*/
h1, h2, h3, h4, h5, h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: $headings-color;
}

[class*="text-"] h1, [class*="text-"] .h1 {
  color: inherit;
}

[class*="text-"] h2, [class*="text-"] .h2 {
  color: inherit;
}

[class*="text-"] h3, [class*="text-"] .h3 {
  color: inherit;
}

[class*="text-"] h4, [class*="text-"] .h4 {
  color: inherit;
}

[class*="text-"] h5, [class*="text-"] .h5 {
  color: inherit;
}

[class*="text-"] h6, [class*="text-"] .h6 {
  color: inherit;
}