.archived-event-banner {
  display: flex;
  margin: 20px;
  background: #fdf2dc;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ffbb33;
  color: black;
}

.archived-event-banner-icon {
  margin-right: 10px;
}

.archived-event-banner-icon > * {
  color: #ffbb33;
}
