/*Sidebar Styles*/

.side-nav {
  width: $side-nav-width;
  background-color: $sidebar-bg !important;
  color: $sidebar-text-color !important;
  border-right: 0 none !important;
  z-index: 1250 !important;

  & .user-profile {
    background-color: $sidebar-bg;
    padding: 25px 20px;
  }

  & .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 55px !important;
    width: 55px !important;
    border: 2px solid var(--audience-color);
  }

  & .user-detail {
    width: 100%;

    & .user-name {
      color: $sidebar-hover-color;
      cursor: pointer;
      font-size: 1.2em;
      font-weight: 400;
      white-space: break-spaces !important;
      display: flex;
      align-items: center;
      word-break: break-word;
      width: 100%;

      & span {
        width: 100%;
      }
    }
  }
}

.side-nav-root {
  z-index: 1200 !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: $audience-color !important;
}

ul.nav-menu {
  position: relative;
  font-size: $font-size-base;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px;
}

ul.nav-menu li.menu {
  & + .nav-header {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    border-top: solid $border-width lighten($sidebar-bg, 6%);
  }
  & .sub-menu {
    max-height: 0;
    transition: max-height 0.3s;
    overflow: hidden;
  }
  &.open > .sub-menu {
    max-height: 500px;
  }

  &.open.closed > .sub-menu {
    max-height: 0;
  }

  &.open {
    & .menu.active > .sub-menu {
      display: block;
    }
  }
}

ul.nav-menu li button,
ul.nav-menu li a {
  color: $sidebar-text-color;
  display: block;
  font-size: 14px;
  min-width: inherit !important;
  padding: 10px 35px 10px 20px;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  @include border-radius(0);

  &:focus,
  &:active {
    outline: none;
  }
}

ul.nav-menu li button {
  border-bottom: solid 2px transparent;
  width: 100%;
  text-align: left;
}

ul.nav-menu li.menu.open button {
  border-bottom: solid 2px #ff026e;
}

ul.nav-menu li.menu.open .title-sidebar {
  font-weight: 600;
  color: var(--audience-color)
}

ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-color;
    padding: 10px 20px;
    font-size: 11px;

    &:first-child {
      margin-top: 10px;
    }
  }
}

ul.nav-menu > li.open > button,
ul.nav-menu > li.open > button:hover,
ul.nav-menu > li.open > button:focus,
ul.nav-menu > li.open > a {
  & span {
    color: var(--audience-color)
  }
}

ul.nav-menu li.menu > button:focus:before,
ul.nav-menu li.menu > button:hover:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: $sidebar-hover-color;
}

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: $sidebar-bg-open;
  overflow: hidden;
}

ul.nav-menu li ul > li > a,
ul.nav-menu li ul > li > button {
  padding-left: 52px;
}

ul.nav-menu li ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > button {
  padding-left: 72px;
}

ul.nav-menu li ul > li > ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > ul > li > button {
  padding-left: 92px;
}

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > ul > li > ul > li > button {
  padding-left: 112px;
}

ul.nav-menu li.menu > button:before,
ul.nav-menu li.menu > button:hover:before {
  color: $sidebar-text-color;
  content: '\f2fb';
  display: inline-block;
  float: right;
  font: {
    family: 'Material-Design-Iconic-Font';
    size: 15px;
  }
  margin-left: 10px;
  position: relative;
  font-weight: normal;
}

ul.nav-menu li.menu {
  background-color: $sidebar-bg-darken;
  transition: background-color 0.3s;
}

ul.nav-menu li.menu.open {
  background-color: $white;
}

ul.nav-menu li.menu.open > button:before,
ul.nav-menu li.menu.open > button:hover:before {
  content: '\f2f9';
  color: var(--audience-color);
}

ul.nav-menu li.menu.open.closed > button:before,
ul.nav-menu li.menu.open.closed > button:hover:before {
  content: '\f2fb';
  color: var(--audience-color);
}

ul.nav-menu li.menu.no-arrow > a:before {
  display: none;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  @include rotate(90deg);
}

ul.nav-menu li.menu.open .sub-menu li > a {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    @include translateY(-50%);
    display: none;
    background-color: $white;
    width: 6px;
    height: 6px;
    @include border-radius($border-radius-circle);
  }

  &.active:after {
    display: inline-block;
  }
}

ul.nav-menu li.menu.open .sub-menu li > a.active {
  & span {
    font-weight: 600;
    color: var(--audience-color)
  }
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  color: var(--audience-color)
}

ul.nav-menu .badge {
  line-height: 13px;
  margin-bottom: 0;
}

.background-logo {
  background-color: $sidebar-bg-darken;
  padding: 15px 0;
}

.background-logo .app-logo {
  display: flex;
}

.logo-powered-by {
  width: 65%;
  margin: 0 auto;
}
