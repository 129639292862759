.welcome-container {
  padding-block: 10px;
  background-color: #ffffff;
}

.welcome-title {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: var(--audience-color);
}

.todo-list {
  padding-top: 10px;
}

.el-list {
  padding-bottom: 10px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.el-list.active {
  text-decoration: line-through;
  pointer-events: none;
}

.todo-name {
  transition: color 0.2s;
  color: rgb(128, 128, 128);
}

.el-list.active .todo-name {
  color: inherit;
}

.el-list:hover .todo-name {
  color: var(--audience-color);
}

.el-list.active .number-marker {
  border-color: var(--audience-color);
  color: var(--audience-color);
}

.number-marker {
  border: 2px solid #a1a1a1;
  border-radius: 50%;
  padding: 0 4px;
  font-weight: 600;
  transition: border-color 0.2s;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-marker > * {
  font-size: 16px !important;
}
